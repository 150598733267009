import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { SERVER_URI, displayableSizeToSizeMap } from "../../common/Constants";
import { getWindowDimensions } from "../../common/Utils";
import ProductSizes from "./ProductSizes";
import { useMutation } from "@apollo/client";
import { ADD_TO_CART_MUTATION } from "../../graphql/cart/mutations";
import LoadingSpinner from "../loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCartStore } from "../../redux/slices/CartSlice";
import { IRootState } from "../../redux/store";
import toast, { Toaster } from "react-hot-toast";

interface IProductDetailsComponent {
    itemData: Record<string, any>;
}

interface ICarouselImage {
    original: string;
    thumbnail: string;
    embedUrl?: string;
    renderItem?: any;
}

const renderVideo = (item: any) => {
    return (
        <div className="h-[32rem]">
            <video
                controls={false}
                src={item.embedUrl}
                autoPlay
                muted
                loop
                playsInline
            />
        </div>
    );
};

const ProductDetailsComponent = ({ itemData }: IProductDetailsComponent) => {
    const navigate = useNavigate();
    const images: ICarouselImage[] = [];
    const [quantity, setQuantity] = useState<0 | 1 | 2>(0);
    const [selectedSize, setSelectedSize] = useState<string>();
    const dispatch = useDispatch();
    const { customerStore } = useSelector((state: IRootState) => state);

    const [imageGalleryThumbnailPosition, setImageGalleryThumbnailPosition] =
        useState<"bottom" | "left" | undefined>(
            getWindowDimensions().width > 1024 ? "left" : "bottom",
        );
    useEffect(() => {
        function handleResize() {
            getWindowDimensions().width > 1024
                ? setImageGalleryThumbnailPosition("left")
                : setImageGalleryThumbnailPosition("bottom");
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    images.push({
        embedUrl: "https://api.jasavya.com/images/test.mp4",
        original: "https://api.jasavya.com/images/File89.jpeg",
        thumbnail: "https://api.jasavya.com/images/File89.jpeg",
        renderItem: renderVideo.bind(this),
    });

    images.push({
        original: SERVER_URI.concat("/").concat(
            itemData?.primaryImage?.imageUrl,
        ),
        thumbnail: SERVER_URI.concat("/").concat(
            itemData?.primaryImage?.imageUrl,
        ),
    });
    itemData?.additionalImages?.forEach((image: any) => {
        images.push({
            original: SERVER_URI.concat("/").concat(image?.imageUrl),
            thumbnail: SERVER_URI.concat("/").concat(image?.imageUrl),
        });
    });
    const [addToCart, { data: addToCartData, loading: addToCartLoading }] =
        useMutation(ADD_TO_CART_MUTATION, {
            variables: {
                customerId: customerStore.customerId,
                itemQuantity: quantity,
                itemId: itemData.id,
                itemSize: displayableSizeToSizeMap[selectedSize ?? ""],
                itemType: String(itemData.__typename).toUpperCase(),
            },
        });

    const addToCartSuccessfulToast = () => toast.success("Added to cart");
    const addToCartFailureToast = () =>
        toast.error(addToCartData?.addToCart?.error?.errorMessage);
    const addToCartChooseQuantityToast = () => toast("Add upto 2 quantities");
    const addToCartChooseSizeToast = () =>
        toast("Please choose a size to continue");

    useEffect(() => {
        if (addToCartData?.addToCart?.success?.cartItemId) {
            // addCartItem(addToCartData?.addToCart?.success?.cartItemId, itemData.id, String(itemData.__typename).toUpperCase(), quantity, displayableSizeToSizeMap[selectedSize ?? '']);
            dispatch(
                addToCartStore({
                    itemQuantity: quantity,
                    itemId: itemData.id,
                    itemSize: displayableSizeToSizeMap[selectedSize ?? ""],
                    itemType: String(itemData.__typename).toUpperCase(),
                }),
            );
            addToCartSuccessfulToast();
        }
        if (addToCartData?.addToCart?.error) {
            addToCartFailureToast();
        }
    }, [addToCartData]);

    return (
        <div className="mt-24">
            {addToCartLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="w-full flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-start">
                    <div className="w-96 lg:min-w-[28rem]">
                        <ImageGallery
                            items={images}
                            thumbnailPosition={imageGalleryThumbnailPosition}
                            showNav={false}
                            showPlayButton={false}
                            showBullets
                            showFullscreenButton={false}
                            disableThumbnailScroll={false}
                            showIndex={true}
                        />
                    </div>
                    <div className="m-10 p-2 flex flex-col min-w-[30rem]">
                        <div
                            className="text-3xl text-left mb-5 ml-10 mr-10"
                            datatest-id="productDetailName"
                        >
                            {itemData.name}
                        </div>
                        <div
                            className="text-left mr-10 mb-1 ml-10 mt-0 text-xl font-medium text-navbar font-lato inline-block"
                            datatest-id="productDetailPrice"
                        >
                            MRP: ₹{itemData.discountedPricePerPiece}
                            {"  "}
                            <del>{itemData.pricePerPiece}</del>
                        </div>
                        <div
                            className="text-left mr-10 mb-1 ml-10 mt-0 text-sm"
                            datatest-id="productDetailsGST"
                        >
                            {"(inclusive of all taxes)"}
                        </div>
                        <div
                            className="text-left mr-10 mb-5 ml-10 mt-0 text-xs text-slate-400"
                            datatest-id="productDetailPaymentMethodsAccepted"
                        >
                            {"UPI. Card. Cash on Delivery accepted"}
                        </div>
                        <div
                            className="text-left m-10 mt-0"
                            datatest-id="productDetailDescription"
                        >
                            {itemData.description}
                        </div>
                        <div
                            className="text-left m-10 mt-0 mb-0"
                            datatest-id="productDetailSize"
                        >
                            <ProductSizes
                                itemData={itemData}
                                selectedSize={selectedSize}
                                setSelectedSize={setSelectedSize}
                            />
                        </div>
                        <div className="flex flex-row items-start">
                            <div
                                datatest-id="productDetailsQuantity"
                                className="flex items-center justify-between w-24 p-3 m-10 mt-5 mr-6 mb-2 rounded-3xl border-solid border-2 border-navbar/[0.4]"
                            >
                                <div
                                    className="cursor-pointer select-none"
                                    onClick={() => {
                                        setQuantity(quantity == 2 ? 1 : 0);
                                    }}
                                >
                                    -
                                </div>
                                <div>{quantity}</div>
                                <div
                                    className="cursor-pointer select-none"
                                    onClick={() => {
                                        setQuantity(quantity == 0 ? 1 : 2);
                                    }}
                                >
                                    +
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    if (
                                        customerStore.customerId &&
                                        selectedSize &&
                                        quantity > 0
                                    ) {
                                        addToCart();
                                    } else {
                                        if (!customerStore.customerId) {
                                            navigate("/login");
                                        } else if (!selectedSize) {
                                            addToCartChooseSizeToast();
                                        } else if (quantity <= 0) {
                                            addToCartChooseQuantityToast();
                                        }
                                    }
                                }}
                                className="mt-5 pt-3 pb-3 flex pl-10 pr-10 bg-white border-navbar/[0.4] border-2 text-navbar rounded-3xl hover:bg-navbar hover:text-white cursor-pointer hover:border-white"
                            >
                                Add to Cart
                            </div>
                        </div>
                        <div className="m-10 mt-0 w-[18rem] bg-navbar text-center text-white p-5 rounded-3xl cursor-pointer">
                            Buy Now
                        </div>
                    </div>
                </div>
            )}
            <Toaster
                position={
                    getWindowDimensions().width < 640
                        ? "bottom-center"
                        : "top-center"
                }
            />
        </div>
    );
};

export default ProductDetailsComponent;
