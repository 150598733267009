import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
mutation login(
    $email: String, 
    $phone: String,
    $password: String!
)
    {
    login(input: {
      email: $email,
      phone: $phone,
      password: $password
    }){
      success{
        customerId
      }
      error{
        errorCode
        errorMessage
      }
    }
  }`