import React, { useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store";
import { useQuery } from "@apollo/client";
import { GET_CART } from "../../graphql/cart/queries";
import LoadingSpinner from "../loading/LoadingSpinner";
import { SERVER_URI_PATH } from "../../common/Constants";

const CartComponent = () => {
    const { customerStore } = useSelector((state: IRootState) => state);
    const [subTotal, setSubTotal] = useState(0);
    const { loading, data } = useQuery(GET_CART, {
        variables: { customerId: customerStore.customerId },
    });

    useEffect(() => {
        if (data?.getCart?.cartItems) {
            data.getCart.cartItems.forEach((cartItem: any) => {
                if (subTotal === 0) {
                    setSubTotal(
                        subTotal +
                            Number(cartItem.item.discountedPricePerPiece),
                    );
                }
            });
        }
    }, [data]);

    if (loading) {
        return <LoadingSpinner />;
    }
    return (
        <div className="w-full">
            <div className="mt-12 md:mt-20 p-2 pt-4 pb-4 w-full text-sm bg-navbar text-white flex-row flex items-center cursor-pointer">
                <div className="ml-1 mr-2">
                    <FaMapMarkerAlt />
                </div>
                Select address
                <div className="text-lg">
                    <RiArrowDropDownLine />
                </div>
            </div>
            <div className="w-full ml-4 mt-10">Cart</div>
            <div className="text-2xl ml-4 mt-2 font-bold md:hidden">
                Subtotal - ₹{subTotal}
            </div>
            <div className="mt-5 m-2 md:w-[18rem] bg-navbar text-center text-white p-5 rounded-3xl cursor-pointer md:hidden">
                Proceed to checkout - {data?.getCart?.totalQuantity} item(s)
            </div>
            {data?.getCart?.cartItems?.map((cartItem: any, index: Number) => {
                return (
                    <div className="flex flex-row p-1">
                        <div className="w-1/3">
                            <img
                                src={
                                    SERVER_URI_PATH +
                                    String(cartItem.item.primaryImage.imageUrl)
                                }
                            />
                        </div>
                        <div className="flex w-2/3 flex-col justify-between items-start pl-2 pr-2">
                            <div>
                                <div className="text-lg line-clamp-3 mb-4">
                                    {cartItem.item.name}
                                    <div className="text-xs">
                                        {cartItem.item.description}
                                    </div>
                                </div>
                                <div className="line-through text-sm">
                                    ₹{cartItem.item.pricePerPiece}
                                </div>
                                <div className="text-xl font-bold">
                                    ₹{cartItem.item.discountedPricePerPiece}
                                </div>
                            </div>
                            <div>
                                <div
                                    datatest-id="productDetailsQuantity"
                                    className="flex items-center justify-between w-20 text-sm p-2 rounded-3xl border-solid border-2 border-navbar/[0.4]"
                                >
                                    <div
                                        className="cursor-pointer select-none"
                                        onClick={() => {
                                            // setQuantity(quantity == 2 ? 1 : 0);
                                        }}
                                    >
                                        -
                                    </div>
                                    <div>{cartItem.itemQuantity}</div>
                                    <div
                                        className="cursor-pointer select-none"
                                        onClick={() => {
                                            // setQuantity(quantity == 0 ? 1 : 2);
                                        }}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CartComponent;
