import React from 'react'
import LatestItemsPreview from './LatestItemsPreview'

interface showcaseProps {
  kurtisList: object[]
  anarkaliList: object[]
  coordsList: object[]
  nairaList: object[]
  aliasList: object[]
  unstitchedSuitsList: object[]
}

const Showcase = ({ kurtisList, anarkaliList, coordsList, nairaList, aliasList, unstitchedSuitsList }: showcaseProps): JSX.Element => {
  return (
    <div>
      <div className="flex justify-center mt-8 text-base sm:text-xl font-lato text-text-primary">
        <span className='text-black font-light'>--- Fresh arrivals ---</span>
      </div>
      {kurtisList?.length !== 0 && <LatestItemsPreview itemList={kurtisList} />}
      {anarkaliList?.length !== 0 && <LatestItemsPreview itemList={anarkaliList} />}
      {coordsList?.length !== 0 && <LatestItemsPreview itemList={coordsList} />}
      {nairaList?.length !== 0 && <LatestItemsPreview itemList={nairaList} />}
      {aliasList?.length !== 0 && <LatestItemsPreview itemList={aliasList} />}
      {unstitchedSuitsList?.length !== 0 && <LatestItemsPreview itemList={unstitchedSuitsList} />}
    </div>
  )
}

export default Showcase
