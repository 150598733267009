import React, { useState } from "react";
import { HERO_LIST } from "../Constants";
import { onHeroEnd } from "./Utils";

const Hero = () => {
  const [heroIndex, setHeroIndex] = useState(0);
  return (
    <div
      className="bg-primary h-screen w-screen flex justify-center -z-10 absolute"
      datatest-id="hero"
    >
      <video
        playsInline
        controls={false}
        autoPlay
        muted={true}
        src={HERO_LIST[0]}
        className="object-cover w-full"
        onEnded={(event) => {
          onHeroEnd(event, heroIndex, setHeroIndex);
        }}
      ></video>
    </div>
  );
};

export default Hero;
