import React, { useEffect, useState } from "react";
import { LOGIN_MUTATION } from "../../graphql/login/mutations";
import { useMutation } from "@apollo/client";
import { validateEmail, validatePhoneNumber } from "../../common/Utils";
import LoadingSpinner from "../loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCustomerId } from "../../redux/slices/CustomerSlice";

interface ILoginPassword {
    loginEmailPhoneValue: string;
    setLoginEmailPhoneValueEmpty: Function;
}

const LoginPassword = ({
    loginEmailPhoneValue,
    setLoginEmailPhoneValueEmpty,
}: ILoginPassword) => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const updateLoginPassword = (event: any) => {
        setPassword(event.target.value);
    };
    const dispatch = useDispatch();

    const [login, { data, loading }] = useMutation(LOGIN_MUTATION, {
        variables: {
            email: validateEmail(loginEmailPhoneValue)
                ? loginEmailPhoneValue
                : null,
            phone: validatePhoneNumber(loginEmailPhoneValue)
                ? "+91" + loginEmailPhoneValue
                : null,
            password: password,
        },
    });
    useEffect(() => {
        if (data?.login?.success?.customerId) {
            dispatch(updateCustomerId(data?.login?.success?.customerId));
            navigate("/profile", { replace: true });
        }
    }, [data]);

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className="border p-5 border-black/15 m-10 w-[342px]">
            <div className="text-center text-3xl mb-10">Jasavya</div>
            <div className="text-2xl font-medium">Sign in</div>
            <div className="mt-5 font-medium text-xs">
                Email or mobile phone number (+91)
            </div>
            <div className="font-medium">{loginEmailPhoneValue}</div>
            <div
                className="text-xs text-navbar cursor-pointer"
                onClick={() => setLoginEmailPhoneValueEmpty(false)}
            >
                Change
            </div>
            <div className="flex flex-row justify-between items-center mt-5 text-sm w-[300px]">
                <div className="">Password</div>
                <div className="text-navbar cursor-pointer">
                    Forgot password
                </div>
            </div>
            <input
                value={password}
                name="loginPassword"
                type="password"
                datatest-id="loginPasswordInput"
                className="w-[300px] border border-black/30 text-lg mt-2 p-1"
                onChange={updateLoginPassword}
            />
            <div
                onClick={() => {
                    login();
                }}
                className="mt-5 w-[300px] bg-navbar text-center text-white p-2 rounded cursor-pointer"
            >
                Sign in
            </div>
        </div>
    );
};

export default LoginPassword;
