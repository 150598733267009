import { gql } from "@apollo/client";

export const GET_CART = gql`
query getCart($customerId: CustomerId!){
  getCart(input: {
    customerId: $customerId
  }){
    totalQuantity
    cartItems{
      itemQuantity
      size
      isItemQuantityAvailable
      item{
        ... on Alia{
          primaryImage{
            imageUrl
          }
          description
          name
          discountedPricePerPiece
          pricePerPiece
        }
        ... on Kurti{
          primaryImage{
            imageUrl
          }
          description
          name
          discountedPricePerPiece
          pricePerPiece
        }
        ... on CoOrd{
          primaryImage{
            imageUrl
          }
          description
          name
          discountedPricePerPiece
          pricePerPiece
        }
        ... on Anarkali{
          primaryImage{
            imageUrl
          }
          description
          name
          discountedPricePerPiece
          pricePerPiece
        }
        ... on UnStitchedSuit{
          primaryImage{
            imageUrl
          }
          description
          name
          discountedPricePerPiece
          pricePerPiece
        }
        ... on NairaCut{
          primaryImage{
            imageUrl
          }
          description
          name
          discountedPricePerPiece
          pricePerPiece
        }
      }
    }
  }
}`;