import React, { RefObject } from 'react';

const HeroSecondary = ({homeContentRef}: {homeContentRef: RefObject<HTMLDivElement>}) => {
    return(
        <div className="h-[calc(100vh)] min-w-screen flex flex-col justify-center items-center">
            <div className="text-white text-7xl font-medium -mt-16">
                JASAVYA
            </div>
            <div className="text-white text-sm">
                आप जैसी एक काव्या . A poetry like you.
            </div>
            <div className="text-white bg-navbar p-4 m-5 rounded-3xl cursor-pointer hover:bg-white hover:text-navbar" onClick={() => {
                if (homeContentRef && homeContentRef.current) {
                    homeContentRef.current.scrollIntoView({behavior: 'smooth'});
                  } 
            }}>
                Shop now
            </div>
        </div>
    );
}

export default HeroSecondary;