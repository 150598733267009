import React from 'react'
import { Circles } from 'react-loader-spinner'

const LoadingSpinner = (): JSX.Element => {
  return (
      <div className='flex justify-center align-middle mt-28'>
        <Circles
          height="50"
          width="50"
          color="#642926"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
  )
}

export default LoadingSpinner
