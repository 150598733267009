import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { SEND_OTP_FOR_REGISTRATION_MUTATION } from "../../graphql/signup/mutations";
import { getWindowDimensions, validatePhoneNumber } from "../../common/Utils";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../loading/LoadingSpinner";
import SendOTPForRegistration from "./SendOTPForRegistration";
import SignupForm from "./SignupForm";

const SignupComponent = () => {
    const [signupPhoneValue, setSignupPhoneValue] = useState("");
    const [registrationFormVisible, setRegistrationFormVisible] =
        useState(false);

    const updateSignupPhoneInputValue = (event: any) => {
        setSignupPhoneValue(event.target.value);
    };

    // Shared between OTP and register pages
    const [sendOTPForRegistration, { data, loading }] = useMutation(
        SEND_OTP_FOR_REGISTRATION_MUTATION,
        {
            variables: {
                phone: validatePhoneNumber(signupPhoneValue)
                    ? "+91"+signupPhoneValue
                    : null,
            },
        },
    );

    const otpSendingUnsuccessfulToast = (message: string) =>
        toast.error(message);

    useEffect(() => {
        if (data?.sendOTPForRegistration?.error?.errorMessage) {
            otpSendingUnsuccessfulToast(
                data?.sendOTPForRegistration?.error?.errorMessage,
            );
        }
        if (data?.sendOTPForRegistration?.success?.isSuccess) {
            setRegistrationFormVisible(true);
        }
    }, [data]);

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className="w-full flex flex-row justify-center mt-24">
            {!registrationFormVisible ? (
                <SendOTPForRegistration
                    signupPhoneValue={signupPhoneValue}
                    updateSignupPhoneInputValue={updateSignupPhoneInputValue}
                    sendOTPForRegistration={sendOTPForRegistration}
                />
            ) : (
                <SignupForm
                    signupPhoneValue={signupPhoneValue}
                    sendOTPForRegistration={sendOTPForRegistration}
                    setRegistrationFormVisible={setRegistrationFormVisible}
                />
            )}
            <Toaster
                position={
                    getWindowDimensions().width < 640
                        ? "bottom-center"
                        : "top-center"
                }
            />
        </div>
    );
};

export default SignupComponent;
