import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cartStore",
  initialState: {
    cart: [] as Array<Record<string, any>>
  },
  reducers: {
    addToCartStore: (state, param) => {
      const { payload } = param;
      state.cart.push(
        payload
      );
    },
    clearCartStore: (state, param) => {
      state.cart = [];
    },
  },
});
const { actions, reducer } = cartSlice;
export const { addToCartStore, clearCartStore } = actions;
export default reducer;
