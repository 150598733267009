import { gql } from "@apollo/client";

export const GET_LATEST_ITEMS_QUERY = gql`
    {
        alias(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            aliasList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }

        nairaCuts(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            nairaCutsList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }

        coOrds(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            coOrdsList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }

        anarkalis(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            anarkalisList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }

        kurtis(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            kurtisList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }
        unstitchedSuits(
            input: { pageToken: { pageNumber: 1, perPageCount: 8 } }
        ) {
            unStitchedSuitsList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }
    }
`