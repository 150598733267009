const CATEGORY_SELECTION_MAP = {
  kurtis: false,
  nairaCuts: false,
  aliaCuts: false,
  anarkalis: false,
  coOrds: false,
};
const SERVER_URI = "https://api.jasavya.com";
const SERVER_URI_PATH = "https://api.jasavya.com/";

export { CATEGORY_SELECTION_MAP, SERVER_URI, SERVER_URI_PATH };

export const sizeToDisplayableSizeMap: Record<string, string> = {
  XXXXXXL: '6XL',
  XXXXXL: '5XL',
  XXXXL: '4XL',
  XXXL: '3XL',
  XXL: '2XL',
  XL: 'XL',
  L: 'L',
  M: 'M',
}

export const displayableSizeToSizeMap: Record<string, string> = {
  '6XL': 'XXXXXXL',
  '5XL': 'XXXXXL',
  '4XL': 'XXXXL',
  '3XL': 'XXXL',
  '2XL': 'XXL',
  'XL': 'XL',
  'L': 'L',
  'M': 'M',
}
