import { gql } from "@apollo/client";

export const SEND_OTP_FOR_REGISTRATION_MUTATION = gql`
    mutation sendOTPForRegistration($phone: String!) {
        sendOTPForRegistration(input: { phone: $phone }) {
            success {
                isSuccess
            }
            error {
                errorCode
                errorMessage
            }
        }
    }
`;

export const REGISTRATION_MUTATION = gql`
    mutation registerUser(
        $phone: String!
        $password: String!
        $otp: Int!
        $name: String!
    ) {
        registerUser(
            input: {
                phone: $phone
                password: $password
                otp: $otp
                name: $name
            }
        ) {
            success {
                isSuccess
                customerId
            }
            error {
                errorCode
                errorMessage
            }
        }
    }
`;
