import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import HomeContainer from "./containers/home/HomeContainer";
import ProductDetailsContainer from "./containers/productDetails/ProductDetailsContainer";
import LoginContainer from "./containers/login/LoginContainer";
import SignupContainer from "./containers/signup/SignupContainer";
import ProfileContainer from "./containers/profile/ProfileContainer";
import CartContainer from "./containers/cart/CartContainer";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <HomeContainer />,
            },
            {
                path: "/product-details",
                element: <ProductDetailsContainer />,
            },
            {
                path: "/login",
                element: <LoginContainer />,
            },
            {
                path: "/register",
                element: <SignupContainer />,
            },
            {
                path: '/profile',
                element: <ProfileContainer />
            },
            {
              path: '/cart',
              element: <CartContainer />
            },
            // {
            //   path: '/wishlist',
            //   element: <WishlistContainer />
            // },
            // {
            //   path: '/kurtis',
            //   element: <KurtiListContainer />
            // },
            // {
            //   path: '/alias',
            //   element: <AliaListContainer />
            // },
            // {
            //   path: '/anarkalis',
            //   element: <AnarkaliListContainer />
            // },
            // {
            //   path: '/nairaCuts',
            //   element: <NairaCutListContainer />
            // },
            // {
            //   path: '/plusSizes',
            //   element: <PlusSizesListContainer />
            // },
            // {
            //   path: '/unstitchedSuits',
            //   element: <UnStitchedSuitListContainer />
            // },
            // {
            //   path: '/coordSets',
            //   element: <CoordListContainer />
            // },
            // {
            //   path: '/accounts',
            //   element: <AccountsContainer />,
            //   children: [
            //     {
            //       path: '/accounts',
            //       element: <UserProfileComponent />
            //     },
            //     {
            //       path: '/accounts/profile',
            //       element: <UserProfileComponent />
            //     },
            //     {
            //       path: '/accounts/address',
            //       element: <AddressComponent />
            //     },
            //     {
            //       path: '/accounts/wishlist',
            //       element: <WishlistComponent />
            //     }
            //   ]
            // }
        ],
        // errorElement: <RootErrorBoundary />
    },
]);
