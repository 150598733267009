import React, { useEffect } from "react";
import ProfileComponent from "../../components/profile/ProfileComponent";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";

const ProfileContainer = () => {
    useEffect(() => {
        if (!customerStore.customerId) {
            navigate("/login", {replace: true});
        }
    }, []);
    const navigate = useNavigate();
    const { customerStore } = useSelector((state: IRootState) => state);
    return (
        <div>
            <ProfileComponent />
        </div>
    );
};

export default ProfileContainer;
