import { combineReducers, configureStore } from "@reduxjs/toolkit";
import customerReducer from "./slices/CustomerSlice";
import cartReducer from "./slices/CartSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const rootReducer = combineReducers({ 
    customerStore: customerReducer,
    cartStore: cartReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store)
export type IRootState = ReturnType<typeof rootReducer>;
export default store;
