import React from "react";
import { gql } from "@apollo/client";

export const ADD_TO_CART_MUTATION = gql`
  mutation addToCart(
    $itemQuantity: Int!
    $customerId: CustomerId!
    $itemType: FashionItemType!
    $itemSize: FashionItemSize!
    $itemId: String!
  ) {
    addToCart(
      input: {
        itemQuantity: $itemQuantity
        customerId: $customerId
        itemType: $itemType
        itemId: $itemId
        itemSize: $itemSize
      }
    ) {
      success {
        cartItemId
      }
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
