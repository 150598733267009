import { createSlice } from '@reduxjs/toolkit';

const customerSlice = createSlice({
    name: "customerStore",    
    initialState: {
        customerId: null,
    },    
    reducers: {
    updateCustomerId: (state, param) => {
        const { payload } = param;
        state.customerId = payload;
    },
    resetCustomerId: (state, param) => {
        state.customerId = null
    }
    }
});const { actions, reducer } = customerSlice
export const { updateCustomerId, resetCustomerId } = actions;
export default reducer;