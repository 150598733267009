import React from "react";
import "./App.css";
import { Outlet } from "react-router-dom";
import PromoBar from "./components/home/hero/PromoBar";
import NavBar from "./components/navbar/NavBar";
import ScrollToTop from "./common/ScrollToTop";

function App() {
  return (
    <div>
      <ScrollToTop/>
      <PromoBar />
      <NavBar />
      <Outlet/>
    </div>
  );
}

export default App;
