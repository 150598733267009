import React from "react";
import { useNavigate } from "react-router-dom";
import { MdCurrencyRupee } from "react-icons/md";
import { SERVER_URI_PATH } from "../../common/Constants";

interface IItemListRecord {
    itemList?: Array<Record<string, any>>;
    shouldSliceForSmallDevices: boolean;
}

// shouldSliceForSmallDevices is used in home page to show latest item preview
const ItemsListGrid = ({
    itemList,
    shouldSliceForSmallDevices,
}: IItemListRecord): JSX.Element => {
    let itemListsm;
    if (shouldSliceForSmallDevices) {
        itemListsm = itemList?.slice(0, 6);
    }
    return (
        <div>
            {shouldSliceForSmallDevices ? (
                <div>
                    <div className="flex w-full flex-wrap justify-start lg:hidden">
                        {itemListsm?.map((item, index) => {
                            return (
                                <ItemCard
                                    itemId={item.id}
                                    itemType={item.__typename}
                                    title={item.name}
                                    price={item.pricePerPiece}
                                    primaryImageUrl={
                                        SERVER_URI_PATH +
                                        String(item.primaryImage.imageUrl)
                                    }
                                    key={index}
                                />
                            );
                        })}
                    </div>
                    <div className="hidden w-full flex-wrap justify-start lg:flex">
                        {itemList?.map((item, index) => {
                            return (
                                <ItemCard
                                    itemId={item.id}
                                    itemType={item.__typename}
                                    title={item.name}
                                    price={item.pricePerPiece}
                                    primaryImageUrl={
                                        SERVER_URI_PATH +
                                        String(item.primaryImage.imageUrl)
                                    }
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </div>
            ) : null}
            {!shouldSliceForSmallDevices ? (
                <div className="flex w-full flex-wrap justify-start">
                    {itemList?.map((item, index) => {
                        return (
                            <ItemCard
                                itemId={item.id}
                                itemType={item.__typename}
                                title={item.name}
                                price={item.pricePerPiece}
                                primaryImageUrl={
                                    SERVER_URI_PATH +
                                    String(item.primaryImage.imageUrl)
                                }
                                key={index}
                            />
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};

interface IItemRecord {
    title: string;
    price: string;
    primaryImageUrl: string;
    itemType: string;
    itemId: string;
}

const ItemCard = ({
    title,
    price,
    primaryImageUrl,
    itemType,
    itemId,
}: IItemRecord): JSX.Element => {
    const navigate = useNavigate();
    const routeToProductDetails = (): void => {
        const itemPath = "/product-details?type=" + itemType + "&id=" + itemId;
        navigate(itemPath);
    };

    return (
        <div className="mb-20 flex w-1/2 flex-col justify-between rounded-md border p-3 sm:w-1/3 lg:w-1/4">
            <div>
                <img
                    onClick={routeToProductDetails}
                    className="h-56 w-full cursor-pointer bg-cover object-cover md:h-60 lg:h-96 xl:h-96 2xl:h-100 3xl:h-104"
                    src={primaryImageUrl}
                />
                <p className="m-1 cursor-pointer text-xxs text-black sm:text-base">
                    {title}
                </p>
            </div>
            <div className="flex flex-col items-end">
                <p className="font-lato mt-1 flex cursor-pointer items-center text-sm font-light text-text-primary md:text-xl">
                    <MdCurrencyRupee className="inline" /> {price}/-
                </p>
                <p className="cursor-pointer text-xs  text-black">
                    (Inclusive GST)
                </p>
            </div>
        </div>
    );
};

export default ItemsListGrid;
