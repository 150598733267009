import Marquee from "react-fast-marquee";
import { IoCash } from "react-icons/io5";
import { getWindowDimensions } from "../../../common/Utils";
var FA = require('react-fontawesome');

const PromoBar = () => {
    return(
        <Marquee autoFill className="h-10 bg-navbar w-screen">
          <div className="flex flex-row shrink items-center justify-around text-white font-thin md:text-sm text-xs max-w-[500px]" style={{width: getWindowDimensions().width}}>
              <div className="flex justify-center items-center">
                <FA name="rocket" />
                <p className="ml-2">Free shipping in India</p>
              </div>
              <div className="flex justify-center items-center">
                <FA name="rotate-left" />
                <p className="ml-2">Easy returns</p>
              </div>
              <div className="flex justify-center items-center">
                <IoCash/>
                <p className="ml-2">Cash on delivery</p>
              </div>
          </div>
        </Marquee>
    );
}

export default PromoBar;