import React, { useState, useEffect } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { clsx } from "clsx";
import { useNavigate } from "react-router-dom";
import NavButtonMobile from "./NavButtonMobile";
import { FaRegUser } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store";
import { BsFillCartCheckFill } from "react-icons/bs";

const NavBar = () => {
    const navigate = useNavigate();

    const { cartStore } = useSelector((state: IRootState) => state);
    const { customerStore } = useSelector((state: IRootState) => state);

    const [isKurtiSelected, setIsKurtiSelected] = useState(
        window.location.pathname === "/kurtis",
    );
    const [isAliaSelected, setIsAliaSelected] = useState(
        window.location.pathname === "/alia",
    );
    const [isCoOrdSelected, setIsCoOrdSelected] = useState(
        window.location.pathname === "/coordSets",
    );
    const [isAnarkaliSelected, setIsAnarkaliSelected] = useState(
        window.location.pathname === "/anarkalis",
    );
    const [isNairaCutSelected, setIsNairaCutSelected] = useState(
        window.location.pathname === "/nairaCuts",
    );
    const [isUnstitchedSuitSelected, setIsUnstitchedSuitSelected] = useState(
        window.location.pathname === "/unstitchedSuits",
    );
    const [isPlusSizesSelected, setIsPlusSizesSelected] = useState(
        window.location.pathname === "/plusSizes",
    );
    const [isLoginSelected, setIsLoginSelected] = useState(
        window.location.pathname === "/login",
    );
    useEffect(() => {
        if (window.location.pathname === "/") {
            setIsKurtiSelected(false);
            setIsAliaSelected(false);
            setIsCoOrdSelected(false);
            setIsAnarkaliSelected(false);
            setIsNairaCutSelected(false);
            setIsUnstitchedSuitSelected(false);
            setIsPlusSizesSelected(false);
            setIsLoginSelected(false);
        }
    });
    const selectKurtis = (): void => {
        setIsKurtiSelected(true);
        setIsAliaSelected(false);
        setIsCoOrdSelected(false);
        setIsAnarkaliSelected(false);
        setIsNairaCutSelected(false);
        setIsUnstitchedSuitSelected(false);
        setIsPlusSizesSelected(false);
        setIsLoginSelected(true);
        navigate("/kurtis?page=1");
    };
    const selectAlia = (): void => {
        setIsKurtiSelected(false);
        setIsAliaSelected(true);
        setIsCoOrdSelected(false);
        setIsAnarkaliSelected(false);
        setIsNairaCutSelected(false);
        setIsUnstitchedSuitSelected(false);
        setIsPlusSizesSelected(false);
        setIsLoginSelected(true);
        navigate("/alias?page=1");
    };
    const selectCoord = (): void => {
        setIsKurtiSelected(false);
        setIsAliaSelected(false);
        setIsCoOrdSelected(true);
        setIsAnarkaliSelected(false);
        setIsNairaCutSelected(false);
        setIsUnstitchedSuitSelected(false);
        setIsPlusSizesSelected(false);
        setIsLoginSelected(true);
        navigate("/coordSets?page=1");
    };
    const selectAnarkali = (): void => {
        setIsKurtiSelected(false);
        setIsAliaSelected(false);
        setIsCoOrdSelected(false);
        setIsAnarkaliSelected(true);
        setIsNairaCutSelected(false);
        setIsUnstitchedSuitSelected(false);
        setIsPlusSizesSelected(false);
        setIsLoginSelected(true);
        navigate("/anarkalis?page=1");
    };
    const selectNairaCut = (): void => {
        setIsKurtiSelected(false);
        setIsAliaSelected(false);
        setIsCoOrdSelected(false);
        setIsAnarkaliSelected(false);
        setIsNairaCutSelected(true);
        setIsUnstitchedSuitSelected(false);
        setIsPlusSizesSelected(false);
        setIsLoginSelected(true);
        navigate("/nairaCuts?page=1");
    };
    const selectUnstitchedSuit = (): void => {
        setIsKurtiSelected(false);
        setIsAliaSelected(false);
        setIsCoOrdSelected(false);
        setIsAnarkaliSelected(false);
        setIsNairaCutSelected(false);
        setIsUnstitchedSuitSelected(true);
        setIsPlusSizesSelected(false);
        setIsLoginSelected(true);
        navigate("/unstitchedSuits?page=1");
    };
    const selectPlusSize = (): void => {
        setIsKurtiSelected(false);
        setIsAliaSelected(false);
        setIsCoOrdSelected(false);
        setIsAnarkaliSelected(false);
        setIsNairaCutSelected(false);
        setIsUnstitchedSuitSelected(false);
        setIsLoginSelected(true);
        setIsPlusSizesSelected(true);
        navigate("/plusSizes?page=1");
    };

    const handleUserIconClicked = () => {
        if (customerStore.customerId) {
            navigate("/profile");
        } else {
            selectLogin();
        }
    };

    const handleCartIconClicked = () => {
        if (customerStore.customerId) {
            navigate("/cart");
        } else {
            selectLogin();
        }
    };

    const selectLogin = (): void => {
        setIsKurtiSelected(false);
        setIsAliaSelected(false);
        setIsCoOrdSelected(false);
        setIsAnarkaliSelected(false);
        setIsNairaCutSelected(false);
        setIsUnstitchedSuitSelected(false);
        setIsPlusSizesSelected(false);
        setIsLoginSelected(true);
        navigate("/login");
    };
    const NavButtonsMobile = (): JSX.Element => {
        return (
            <>
                <NavButtonMobile
                    name={"KURTIS"}
                    onClick={selectKurtis}
                    isSelected={isKurtiSelected}
                />
                <NavButtonMobile
                    name={"ALIA"}
                    onClick={selectAlia}
                    isSelected={isAliaSelected}
                />
                <NavButtonMobile
                    name={"CO ORD SETS"}
                    onClick={selectCoord}
                    isSelected={isCoOrdSelected}
                />
                <NavButtonMobile
                    name={"ANARKALIS"}
                    onClick={selectAnarkali}
                    isSelected={isAnarkaliSelected}
                />
                <NavButtonMobile
                    name={"NAIRA CUTS"}
                    onClick={selectNairaCut}
                    isSelected={isNairaCutSelected}
                />
                <NavButtonMobile
                    name={"UN-STITCHED SUITS"}
                    onClick={selectUnstitchedSuit}
                    isSelected={isUnstitchedSuitSelected}
                />
                {/* <NavButtonMobile
          name={"+PLUS SIZES"}
          onClick={selectPlusSize}
          isSelected={isPlusSizesSelected}
        /> */}
            </>
        );
    };

    const NavBarMobile = () => {
        return (
            <div
                datatest-id="navBar-mobile"
                className="md:hidden flex flex-col justify-between cursor-pointer"
            >
                <div className="flex">
                    <HiMenuAlt2
                        onClick={() => {
                            setIsMobileNavbarOpen(!isMobileNavbarOpen);
                        }}
                        size={25}
                        color="#fff"
                        className="m-3 self-start"
                    />
                    <div
                        className="w-full flex flex-row justify-end mr-5 items-center text-white"
                        datatest-id="navbarActionsDesktop"
                    >
                        <div className="mr-8 lg:mr-10" onClick={handleUserIconClicked}>
                            <FaRegUser className="cursor-pointer lg:text-xl text-sm" />
                        </div>
                        {cartStore.cart.length > 0 ? (
                            <BsFillCartCheckFill className="cursor-pointer lg:text-xl text-sm" onClick={handleCartIconClicked}/>
                        ) : (
                            <FiShoppingCart className="cursor-pointer lg:text-xl text-sm"  onClick={handleCartIconClicked}/>
                        )}
                    </div>
                </div>
                <div
                    className={clsx(
                        "md:hidden flex flex-col items-center bg-navbar w-full transition-all ease-linear absolute top-12",
                        isMobileNavbarOpen
                            ? "visible opacity-100"
                            : "h-0 collapse opacity-0",
                    )}
                >
                    <NavButtonsMobile />
                </div>
            </div>
        );
    };

    const NavBarDesktop = () => {
        return (
            <div
                datatest-id={"navBar-desktop"}
                className="hidden md:flex w-screen h-20 text-xs whitespace-nowrap text-white  flex-row items-center hover:bg-white hover:text-navbar"
            >
                <div
                    className="font-lato font-medium ml-5 lg:ml-20 cursor-pointer"
                    datatest-id="navbar-home"
                >
                    KURTIS
                </div>
                <div
                    className="font-lato font-medium ml-12 cursor-pointer"
                    datatest-id="navbar-shop"
                >
                    ALIA
                </div>
                <div
                    className="font-lato font-medium ml-12 cursor-pointer"
                    datatest-id="navbar-aboutUs"
                >
                    CO ORD SETS
                </div>
                <div
                    className="font-lato font-medium ml-12 cursor-pointer"
                    datatest-id="navbar-policies"
                >
                    ANARKALIS
                </div>
                <div
                    className="font-lato font-medium ml-12 cursor-pointer"
                    datatest-id="navbar-policies"
                >
                    NAIRA CUTS
                </div>
                <div
                    className="font-lato font-medium ml-12 cursor-pointer"
                    datatest-id="navbar-policies"
                >
                    UN-STITCHED SUITS
                </div>
                {/* <div
          className="font-lato font-medium ml-12 cursor-pointer"
          datatest-id="navbar-policies"
        >
          +PLUS SIZES
        </div> */}
                <div
                    className="w-full flex flex-row justify-end mr-5 items-center"
                    datatest-id="navbarActionsDesktop"
                >
                    <div
                        className="mr-2 md:mr-3 lg:mr-10"
                        onClick={handleUserIconClicked}
                    >
                        <FaRegUser className="cursor-pointer lg:text-xl text-sm" />
                    </div>
                    <div>
                        {cartStore.cart.length > 0 ? (
                            <BsFillCartCheckFill className="cursor-pointer lg:text-xl text-sm" onClick={handleCartIconClicked}/>
                        ) : (
                            <FiShoppingCart className="cursor-pointer lg:text-xl text-sm" onClick={handleCartIconClicked}/>
                        )}
                        {/* <FiShoppingCart className="cursor-pointer lg:text-xl text-sm" /> */}
                    </div>
                </div>
            </div>
        );
    };

    const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false);
    return (
        // for home page, the navbar is transparent
        <div
            className={
                window.location.pathname === "/"
                    ? "z-10 absolute bg-transparent w-full"
                    : "z-10 absolute bg-navbar/[0.9] w-full"
            }
        >
            <NavBarMobile />
            <NavBarDesktop />
        </div>
    );
};

export default NavBar;
