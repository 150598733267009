import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOGOUT_MUTATION } from "../../graphql/logout/mutations";
import LoadingSpinner from "../loading/LoadingSpinner";

const ProfileComponent = () => {
    const navigate = useNavigate();

    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(
        LOGOUT_MUTATION,
        {
            variables: {
                email: "",
                phone: "",
            },
        },
    );

    useEffect(() => {
        if(logoutData){
            navigate("/login", {replace: true});
        }
    }, [logoutData]);

    return logoutLoading ? (
        <LoadingSpinner />
    ) : (
        <div className="w-full flex flex-row justify-center mt-24">
            <div className="w-full flex flex-row ml-10 md:ml-48 mr-10 md:mr-48 md:mt-10 justify-between items-center">
                <div className="text-4xl">Your account</div>
                <div
                    className="flex items-center cursor-pointer text-navbar"
                    onClick={() => {
                        localStorage.clear();
                        var cookies = document.cookie.split(";");

                        for (var i = 0; i < cookies.length; ++i) {
                            var myCookie = cookies[i];
                            var pos = myCookie.indexOf("=");
                            var name =
                                pos > -1 ? myCookie.substr(0, pos) : myCookie;
                            document.cookie =
                                name +
                                "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                        }
                        sessionStorage.clear();
                        logout();
                    }}
                >
                    Sign out
                </div>
            </div>
        </div>
    );
};

export default ProfileComponent;
