import React from "react";
import NavBar from "../navbar/NavBar";
import Hero from "./hero/Hero";
import PromoBar from "./hero/PromoBar";
import HeroSecondary from "./hero/HeroSecondary";
import Showcase from "./showcase/Showcase";

interface IHomeComponent {
  kurtisList: object[];
  anarkaliList: object[];
  coordsList: object[];
  nairaList: object[];
  aliasList: object[];
  unstitchedSuitsList: object[];
}

const HomeComponent = ({
  kurtisList,
  anarkaliList,
  coordsList,
  nairaList,
  aliasList,
  unstitchedSuitsList,
}: IHomeComponent) => {
  const homeContentRef = React.createRef<HTMLDivElement>();
  return (
    <div className="">
      <Hero />
      <HeroSecondary homeContentRef={homeContentRef} />
      <div ref={homeContentRef}>
        <Showcase
          kurtisList={kurtisList}
          anarkaliList={anarkaliList}
          coordsList={coordsList}
          nairaList={nairaList}
          aliasList={aliasList}
          unstitchedSuitsList={unstitchedSuitsList}
        />
      </div>
    </div>
  );
};

export default HomeComponent;
