import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { REGISTRATION_MUTATION } from "../../graphql/signup/mutations";
import LoadingSpinner from "../loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCustomerId } from "../../redux/slices/CustomerSlice";

interface ISignupForm {
    signupPhoneValue: string;
    setRegistrationFormVisible: Function;
    sendOTPForRegistration: Function;
}

const SignupForm = ({
    signupPhoneValue,
    setRegistrationFormVisible,
    sendOTPForRegistration,
}: ISignupForm) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [nameValue, setNameValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [otp, setOTP] = useState("");

    const passwordValidateErrorToast = () =>
        toast.error("Password must be between 6 to 30 characters");
    const registrationErrorToast = (message: string) => toast.error(message);

    const validatePassword = () => {
        if (passwordValue.length >= 6 && passwordValue.length <= 30) {
            return true;
        }
        return false;
    };

    const [regsisterUser, { data, loading }] = useMutation(
        REGISTRATION_MUTATION,
        {
            variables: {
                phone: "+91" + signupPhoneValue,
                otp: Number(otp),
                password: passwordValue,
                name: nameValue,
            },
        },
    );

    useEffect(() => {
        if (data?.registerUser?.success?.isSuccess) {
            dispatch(updateCustomerId(data?.registerUser?.success?.customerId));
            if (window.history?.state && window.history.state?.idx >= 2) {
                navigate(-2);
            } else {
                navigate("/");
            }
        } else if (data?.registerUser?.error?.errorMessage) {
            registrationErrorToast(data?.registerUser?.error?.errorMessage);
        }
    }, [data]);

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className="border p-5 border-black/15 m-10 w-[342px]">
            <div className="text-center text-3xl mb-10">Jasavya</div>
            <div className="text-2xl font-medium">Create new account</div>
            <label className="mt-5 font-medium text-xs">Full name</label>
            <input
                name="signupFormNameValue"
                value={nameValue}
                datatest-id="signupFormNameValue"
                onChange={(event) => setNameValue(event.target.value)}
                className="w-[300px] border p-1 border-black/30 text-sm mt-2"
            />

            <label className="mt-5 font-medium text-xs">Password</label>
            <div className="text-xxs">(Password can be between 6-30 chars)</div>
            <input
                name="signupFormPasswordValue"
                type="password"
                value={passwordValue}
                onChange={(event) => setPasswordValue(event.target.value)}
                datatest-id="signupFormPasswordValue"
                className="w-[300px] border p-1 border-black/30 text-sm mt-2"
            />
            <div className="mt-12 bg-blue flex flex-col">
                <label className="font-medium text-xs">OTP</label>
                <span className="text-xxs">
                    (Please enter the OTP that was sent to +91{signupPhoneValue}
                    . If you didn't receive one please{" "}
                    <a
                        className="text-navbar cursor-pointer"
                        onClick={() => {
                            sendOTPForRegistration();
                        }}
                    >
                        click here
                    </a>
                    )
                </span>
                <input
                    name="signupFormOTP"
                    value={otp}
                    onChange={(event) => setOTP(event.target.value)}
                    datatest-id="signupFormOTP"
                    className="w-[300px] border p-1 border-black/30 text-sm mt-2"
                />
            </div>
            <div className="text-xxs mt-2 w-[300px]">
                By continuing you agree to Jasavya's Terms and Conditions and
                Privacy policy
            </div>
            <div
                onClick={() => {
                    if (validatePassword()) {
                        regsisterUser();
                    } else {
                        passwordValidateErrorToast();
                    }
                }}
                className="mt-5 w-[300px] bg-navbar text-center text-sm text-white p-2 rounded cursor-pointer"
            >
                Sign Up
            </div>
            <div
                onClick={() => {
                    setRegistrationFormVisible(false);
                }}
                className="mt-5 w-[300px] bg-white text-center text-sm text-navbar border-navbar border p-2 rounded cursor-pointer"
            >
                Back
            </div>
        </div>
    );
};

export default SignupForm;
