import React from 'react'
import ItemsListGrid from '../../itemListGrid/ItemsListGrid'
interface IItemListRecord {
  itemList: Array<Record<string, any>>
}

const LatestItemsPreview = ({ itemList }: IItemListRecord): JSX.Element => {
  const itemTypeNameMap = new Map([
    ['Kurti', 'KURTIS'],
    ['Alia', 'ALIA CUTS'],
    ['Anarkali', 'ANARKALIS'],
    ['CoOrd', 'CO ORD SETS'],
    ['NairaCut', 'NAIRA CUTS'],
    ['UnStitchedSuit', 'UN-STITCHED SUITS']
  ])
  return (
    <div className='flex flex-col items-center'>
      <div className='text-black font-lato font-medium mt-10 ml-2 text-lg mb-3'>
        NEW IN {itemTypeNameMap.get(itemList?.[0].__typename)}
      </div>
      <ItemsListGrid itemList={itemList} shouldSliceForSmallDevices={true}/>
    </div>
  )
}

export default LatestItemsPreview
