
import React from 'react'
import CartComponent from '../../components/cart/CartComponent'

const CartContainer = () => {
  return (
        <CartComponent/>
  )
}

export default CartContainer