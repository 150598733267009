import { gql } from "@apollo/client";

export const GET_DATA_KURTI = gql`
    query getKurti($itemId: Int) {
        kurti(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
export const GET_DATA_ALIA = gql`
    query getAlia($itemId: Int) {
        alia(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
export const GET_DATA_ANARKALI = gql`
    query getAnarkali($itemId: Int) {
        anarkali(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
export const GET_DATA_COORD = gql`
    query getCoordSet($itemId: Int) {
        coOrd(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
export const GET_DATA_NAIRACUT = gql`
    query getNairaCut($itemId: Int) {
        nairaCut(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
export const GET_DATA_UNSTITCHED_SUIT = gql`
    query getUnstitchedSuit($itemId: Int) {
        unstitchedSuit(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
        }
    }
`