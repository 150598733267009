import React from "react";
import { validatePhoneNumber } from "../../common/Utils";
import toast from "react-hot-toast";

interface ISendOTPForRegistration{
    signupPhoneValue: string,
    updateSignupPhoneInputValue: Function,
    sendOTPForRegistration: Function,
}

const SendOTPForRegistration = (
    {
        signupPhoneValue,
        updateSignupPhoneInputValue,
        sendOTPForRegistration,
    }:ISendOTPForRegistration
) => {
    const wrongPhonePatternToast = () =>
        toast.error("Please enter a valid phone number without +91");
    return (
        <div className="border p-5 border-black/15 m-10 w-[342px]">
            <div className="text-center text-3xl mb-10">Jasavya</div>
            <div className="text-2xl font-medium">Create new account</div>
            <label className="mt-5 font-medium text-xs">
                Mobile phone number (+91)
            </label>
            <input
                name="signupOTPPhoneValue"
                value={signupPhoneValue}
                datatest-id="signupOTPPhoneInput"
                className="w-[300px] border border-black/30 text-sm mt-2 p-1 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                type="number"
                onChange={(event)=>updateSignupPhoneInputValue(event)}
            />
            <div className="text-xxs mt-2 w-[300px]">
                By continuing you agree to Jasavya's Terms and Conditions and
                Privacy policy
            </div>
            <div
                onClick={() => {
                    if (validatePhoneNumber(signupPhoneValue)) {
                        sendOTPForRegistration();
                    } else {
                        wrongPhonePatternToast();
                    }
                }}
                className="mt-5 w-[300px] bg-navbar text-center text-sm text-white p-2 rounded cursor-pointer"
            >
                Send OTP
            </div>
        </div>
    );
};

export default SendOTPForRegistration;
