import { HERO_LIST } from "../Constants";

export const onHeroEnd = (event: any, heroIndex: number, setHeroIndex: Function) => {
    if (heroIndex === 1) {
      setHeroIndex(0);
    } else {
      setHeroIndex(heroIndex + 1);
    }
    event.target.src = HERO_LIST[heroIndex];
    event.target.play();
}