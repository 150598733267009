import clsx from 'clsx';
import React, { useState } from 'react';
import { sizeToDisplayableSizeMap } from '../../common/Constants';

interface IProductSizes{
    itemData: Record<string, any>,
    selectedSize?: string,
    setSelectedSize: Function
}

const ProductSizes = ({itemData, selectedSize, setSelectedSize}: IProductSizes) => {
    const onSizeClick = (size: string): void => {
        setSelectedSize(sizeToDisplayableSizeMap[size]);
    }
    if(itemData?.__typename === "UnStitchedSuit"){
        return null;
    }
    return(
        <div className=''>
            <div className='flex flex-row'>
                <div className='text-sm mb-2'>
                    Size
                </div>
                <div className={clsx('mb-2 text-sm', selectedSize ? '' : 'hidden')}>
                    : {selectedSize}
                </div>
            </div>
            <div datatest-id="productSizeBadgers">
                {
                    itemData.sizes.map(function(size: Record<string, any>, i: number){
                        if(size.isInStock && selectedSize === sizeToDisplayableSizeMap[size.size.toString()]){
                            return(
                                <div key={size.size.toString()} onClick={()=>onSizeClick(size.size.toString())} className='text-bold float-left mr-10 mb-4 p-2 cursor-pointer bg-navbar/[0.4] lg:hover:border-2 lg:hover:bg-white lg:hover:text-navbar text-white w-16 text-center text-sm'>
                                    {sizeToDisplayableSizeMap[size.size.toString()]}
                                </div>
                            );
                        }else if(size.isInStock){
                            return(
                                <div key={size.size.toString()} onClick={()=>onSizeClick(size.size.toString())} className='text-bold float-left mr-10 mb-4 p-2 cursor-pointer bg-navbar lg:hover:border-2 lg:hover:bg-white lg:hover:text-navbar text-white w-16 text-center text-sm'>
                                    {sizeToDisplayableSizeMap[size.size.toString()]}
                                </div>
                            );
                        }
                    })
                }
            </div>
        </div>
    )
}

export default ProductSizes;