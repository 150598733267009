import React from "react";

const NavButtonMobile = ({
  name,
  onClick,
  isSelected,
}: {
  name: string;
  onClick: () => void;
  isSelected: boolean;
}): JSX.Element => {
  return (
    <div className={isSelected ? "bg-white/25" : undefined}>
      <li
        onClick={onClick}
        className="font-lato m-4 cursor-pointer text-white list-none text-xs"
      >
        {name}
      </li>
    </div>
  );
};

export default NavButtonMobile;
