import React, { useState } from "react";
import LoginPassword from "./LoginPassword";
import { getWindowDimensions, validateEmail, validatePhoneNumber } from "../../common/Utils";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const LoginComponent = () => {
	const navigate = useNavigate();
    const [loginEmailPhoneValue, setLoginEmailPhoneValue] = useState("");
    const [isLoginEmailPhoneValueSet, setIsLoginEmailPhoneValueSet] = useState(
        loginEmailPhoneValue.length > 0 ? true : false,
    );
    const wrongPhonePatternToast = () =>
        toast.error("Please enter a valid phone number without +91");
    const handleContinuePressed = () => {
        if (loginEmailPhoneValue.length > 0) {
            if (
                validateEmail(loginEmailPhoneValue) ||
                validatePhoneNumber(loginEmailPhoneValue)
            ) {
                setIsLoginEmailPhoneValueSet(true);
            }else{
                if(!validatePhoneNumber(loginEmailPhoneValue)){
                    wrongPhonePatternToast();
                }
            }
        }
    };
	const handleSignupPress = () => {
		navigate("/register");
	}
    const updateLoginEmailPhoneInputValue = (event: any) => {
        setLoginEmailPhoneValue(event.target.value);
    };
    return (
        <div className="w-full flex flex-row justify-center mt-24">
            {!isLoginEmailPhoneValueSet ? (
                <div className="border p-5 border-black/15 m-10 w-[342px]">
                    <div className="text-center text-3xl mb-10">Jasavya</div>
                    <div className="text-2xl font-medium">Sign in</div>
                    <div className="mt-5 font-medium text-xs">
                        Email or mobile phone number
                    </div>
                    <input
                        name="loginEmailPhone"
                        value={loginEmailPhoneValue}
                        datatest-id="loginEmailPhoneInput"
                        className="w-[300px] border border-black/30 text-lg mt-2 p-1"
                        onChange={updateLoginEmailPhoneInputValue}
                    />
                    <div className="text-xxs mt-2 w-[300px]">
                        By continuing you agree to Jasavya's Terms and
                        Conditions and Privacy policy
                    </div>
                    <div
                        onClick={handleContinuePressed}
                        className="mt-5 w-[300px] bg-navbar text-center text-white p-2 rounded cursor-pointer"
                    >
                        Continue
                    </div>
                    <div 
						onClick={handleSignupPress}
						className="mt-5 w-[300px] bg-white border border-navbar text-center text-navbar p-2 rounded cursor-pointer text-sm">
                        Sign up
                    </div>
                </div>
            ) : (
                <LoginPassword
                    loginEmailPhoneValue={loginEmailPhoneValue}
                    setLoginEmailPhoneValueEmpty={setIsLoginEmailPhoneValueSet}
                />
            )}
            <Toaster
                position={
                    getWindowDimensions().width < 640
                        ? "bottom-center"
                        : "top-center"
                }
            />
        </div>
    );
};

export default LoginComponent;
