import { gql } from "@apollo/client";

export const LOGOUT_MUTATION = gql`
    mutation logout($phone: String, $email: String) {
        logout(input: { phone: $phone, email: $email }) {
            success {
                isSuccess
            }
            error {
                errorMessage
            }
        }
    }
`;
